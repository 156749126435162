import React from 'react';

function ProductDetailAdmin() {
    return (
        <div>
            
        </div>
    );
}

export default ProductDetailAdmin;